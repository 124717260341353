<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="quit">
        <div>
            所在企业列表：
        </div>
        <ul class="quit_ul">
            <li v-for="(i,index) in list" @click="idx=index" :key="i.ent_id">
                <img :src="idx==index?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')" alt="">
                {{i.ent_name}}
            </li>
        </ul>
        <p class="baocun" @click="tuichu_qiye_dakai=true">确定</p>
        <div class='quit_beijing' v-if="tuichu_qiye_dakai" @click="tuichu_qiye_dakai=false">
            <div class="quit_beijing_box" @click.stop>
                <p>您是否确定退出该企业？</p>
                <p>
                    <span @click="tuichu_qiye_dakai=false">取消</span>
                    <span @click="dianji_tuichu_qiye">确定</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { query_user_ent_list } from '../../api/noapi.js'
import { get_my_info, get_rel_list, staff_left_ent } from '../../api/api.js'
export default {
  name: 'quit',
  data () {
    return {
      idx: 0,
      list: [],
      tuichu_qiye_dakai: false
    }
  },
  mounted () {
  },
  created () {
    this.jichu()
  },
  methods: {
    jichu () {
      query_user_ent_list({
        data: {
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10080) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          this.list = date
        } else if (res.data.code == 10081) {
        }
      })
    },
    dianji_tuichu_qiye () {
      console.log(this.list[this.idx])
      console.log(this.$ent_id())

      staff_left_ent({
        data: {
          ent_id: this.list[this.idx].ent_id,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (this.list[this.idx].ent_id == this.$ent_id()) {
            get_my_info({
              data: {
                user_id: this.$jichuxinxi().user_id
              }
            }).then(ras => {
              if (ras.data.code == 10069) {
                // this.jichuxinxi=JSON.parse(ras.data.body.data);
                sessionStorage.setItem('pc_mlbb_jichuxinxi', JSON.stringify(ras.data.body.data))
                // console.log(this.jichuxinxi)
                get_rel_list({
                  data: {
                    user_id: this.$jichuxinxi().user_id
                  }
                }).then(rbs => {
                  if (rbs.data.code == 10091) {
                    console.log(JSON.parse(rbs.data.body.data))
                    const qiye = JSON.parse(rbs.data.body.data)
                    sessionStorage.setItem('pc_mlbb_ent_flag', JSON.stringify(qiye.ent_flag))
                    if (qiye.staff_info != undefined) {
                      sessionStorage.setItem('pc_mlbb_ent_info', JSON.stringify(qiye.staff_info))
                    }
                    // sessionStorage.setItem("pc_mlbb_quanxian", JSON.stringify(qiye.rel_list));
                    // this.jichu()
                    this.$router.push('/home')
                  } else if (rbs.data.code == 10092) {}
                })
              }
            })
          } else {
            this.$message({
              message: '成功退出该企业',
              type: 'success'
            })
            this.tuichu_qiye_dakai = false
            this.jichu()
            this.idx = 0
          }
        } else if (res.data.code == 500) {} else if (res.data.code == 800) {
          this.$message({
            message: '当前员工是主管理员，不能退出企业',
            type: 'warning'
          })
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './geren.scss';
</style>
